.collapse-custom {
  background-color: #fff;
  border: none;
  padding: 12px 16px;
  box-shadow: 0px 1px 2px rgba(38, 50, 69, 0.08);
  border-radius: 3px;
  margin-bottom: 12px;

  .ant-collapse-item {
    border: none;

    .ant-collapse-header {
      font-family: $heading-font;
      font-size: 12px;
      line-height: 15px;
      padding: 0;
      text-transform: uppercase;
      color: $primary-color;
      align-items: center;
      .ant-collapse-expand-icon {
        height: 15px;
        line-height: 15px;

        svg {
          margin-bottom: 4px;
        }
      }

      .ant-collapse-header-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .ant-collapse-content {
      border: none;

      .ant-collapse-content-box {
        margin-top: 12px;
        padding: 0;
      }
    }
  }

  &.collapse-custom-popup {
    border: 1px solid #e1e8ed;
    padding: 0;

    .ant-collapse-header {
      font-size: 13px;
      padding: 12px;
      border-bottom: 1px solid #e1e8ed;
      background: #fafafa;
    }

    .ant-collapse-content {
      padding: 0 16px;
    }
  }

  &.custom-header {

    .ant-collapse-header {
      align-items: center;
    }
  }
}

.collapse-title-black {
  .ant-collapse-header {
    color: #000 !important;
  }
}
